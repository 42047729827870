/* globals siteurl */
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

let photoId;

$('.c-fav-button').click(function () {
  const elem = $(this);
  photoId = $(this).data('photo-id');
  $.post(
    `${siteurl}ajax/toggle_favourite`,
    { photo_id: photoId },
    (data) => {
      if (data.message === 'success') {
        elem.removeClass('favourited-false').removeClass('favourited-0');
        elem.removeClass('favourited-true').removeClass('favourited-1');
        if (data.new_action === 'add') {
          if ($('#page-data').data('current-section') === 'wishlist') {
            $(`#image-thumb-${photoId}`).fadeOut();
          } else {
            elem.addClass('favourited-false');
            // for gallery
            elem.find('use').attr('xlink:href', '#icon-heart-empty');
            // for photo page
            elem.find('.fa-heart').addClass('fa-heart-o').removeClass('fa-heart');
          }
        } else {
          elem.addClass('favourited-true');
          // for gallery
          elem.find('use').attr('xlink:href', '#icon-heart');
          // for photo page
          elem.find('.fa-heart-o').addClass('fa-heart').removeClass('fa-heart-o');
        }
        $(".c-nav__wishlist-total").html(data.favourites_amount);

        if(data.favourites_amount > 0)
          $(".c-nav__wishlist-total").show();
        else
          $(".c-nav__wishlist-total").hide();

      }
    },
    'json',
  );
});

