import $ from 'jquery';

window.jQuery = $;
window.$ = $;

const scrollToHiw = function () {
  const position = $('.homepage-how-it-works-section').offset().top - 120;
  $('html, body').animate({
    scrollTop: position,
  }, 1000);
};
const setNewsletterBox = function () {
  $('.js-newsletter-box').html(() => $('.js-homepage-newsletter-content').html());
};

$('.js-goto-hiw-section').click(scrollToHiw);
setNewsletterBox();

if ($('.js-delete-account-confirm-dialog').length > 0) {
  $.featherlight($('.js-delete-account-confirm-dialog'));
}
