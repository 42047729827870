import $ from 'jquery';

window.jQuery = $;
window.$ = $;

let quoteText;

const updateCurrentQuote = function () {
  quoteText = $(this).data('quote-text');
  $('#current-quote').html(`"${quoteText}"`);
  $(this).addClass('active');
};

$('.quote-image').hover(updateCurrentQuote).mouseout(function () {
  $(this).removeClass('active');
});
