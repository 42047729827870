import $ from 'jquery';
import 'slick-carousel';

window.jQuery = $;
window.$ = $;

$('.js-quotes-slider').slick({
  arrows: false,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        arrows: true,
      },
    },
  ],
});

$('.c-carousel').slick({
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  centerPadding: 0,
  cssEase: 'linear',
  dots: true,
  fade: true,
  speed: 500,
});

$('.c-content-slider').slick({
  arrows: false,
  centerMode: true,
  centerPadding: '30px',
  infinite: true,
  mobileFirst: true,
  //initialSlide: 3,
  //prevArrow: $('.prev'),
  //nextArrow: $('.next'),
  slidesToShow: 1,
  swipeToSlide: true,
  //slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 3,
      },
    },
  ],
});
/*
const prev = document.querySelector('.prev');
const next = document.querySelector('.next');
const slider = document.querySelector('.c-content-slider');
let slides = document.querySelectorAll('.c-content-slider__item');
let sliderWidth = slider.getBoundingClientRect().width;
let scrollDistance = 0;
console.log(slides[0].getBoundingClientRect().width);

prev.addEventListener('click', () => {
  scrollDistance = scrollDistance + sliderWidth;
  slider.setAttribute('style', `transform: translateX(${scrollDistance}px)`);
});
next.addEventListener('click', () => {
  scrollDistance = scrollDistance - sliderWidth;
  slider.setAttribute('style', `transform: translateX(${scrollDistance}px)`);
});
*/
