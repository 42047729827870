import $ from 'jquery';
import Dropzone from 'dropzone';
import swal from 'sweetalert';

window.jQuery = $;
window.$ = $;


const config = {
  timeout: 300000,
  maxFiles: 1,
  paramName: 'userfile', // The name that will be used to transfer the file
  maxFilesize: 250, // MB
  thumbnailWidth: 120,
  thumbnailHeight: null,
  maxThumbnailFilesize: 250,
  // Prevents Dropzone from uploading dropped files immediately
  autoProcessQueue: true,
  parallelUploads: 1,
  acceptedFiles: 'image/jpg, image/jpeg, image/tiff',

  init() {
    const myDropzone = this; // closure

    this.on('thumbnail', function (file) {
      // const errorMess = document.getElementById('error_format').innerText;
      let $format;

      // the size is expressed in bytes probably, so we need a different formula to calculate 1M
      /*
      if (file.size > 1000) {
        console.log(file.size);
        alert('blabla');
        this.removeFile(file);
        return;
      }
      */
      if (file.width > file.height) {
        $format = file.width / file.height;
      } else {
        $format = file.height / file.width;
      }
      let $retval = '';

      /*
      let formats = [
        { format: '3:2', ratio: 1.5 },
        { format: '4:3', ratio: 1.333333 },
        { format: '16:9', ratio: 1.777777 },
        { format: '1:1', ratio: 1 },
        { format: '2:1', ratio: 2 },
        { format: '3:1', ratio: 3 },
        { format: '4:1', ratio: 4 },
        { format: 'din', ratio: 1.42 },
      ];
      let deviation_percentage = 5;

      for( let i=0; i < formats.length; i++ )
      {        
			    let deviation_min = formats[i].ratio - deviation_percentage / 100 * formats[i].ratio;
			    let deviation_max = formats[i].ratio + deviation_percentage / 100 * formats[i].ratio;
          if( $format >= deviation_min && $format <= deviation_max )
          {
            $retval = formats.format;
          }
      }
      */


      /*
      if ($format >= 1.429 && $format <= 1.60) {
        $retval = '3:2';
      } else if ($format >= 1.23 && $format <= 1.43) {
        $retval = '4:3';
      } else if ($format >= 1.67 && $format <= 1.87) {
        $retval = '16:9';
      } else if ($format >= 0.90 && $format <= 1.10) {
        $retval = '1:1';
      } else if ($format >= 1.90 && $format <= 2.10) {
        $retval = '2:1';
      } else if ($format >= 2.90 && $format <= 3.10) {
        $retval = '3:1';
      } else if ($format >= 3.90 && $format <= 4.10) {
        $retval = '4:1';
      } else if ($format === 1.42) {
        $retval = 'din';
      }
      */

      if (!$retval && !isNaN($format)) {
        //console.log("not valid format");
        //this.removeFile(file);
        //swal(errorMess);
      }
    });

    this.on('error', function (file, message) {
      swal(message);
      this.removeFile(file);
    });

    this.on('success', (file, responseText) => {

      //console.log(responseText);
      const respond = JSON.parse(responseText);

      if (respond.status === 'error') {
        swal({
          text: respond.message,
          icon: 'error',
        });
        this.removeFile(file);
        return;
      }

      setTimeout(() => {
        window.location.href = respond.printRedirect;
      }, 1000);
    });
  },
};


Dropzone.autoDiscover = false;
$('.dropzone').each(function () {
  $(this).dropzone(config);
});

$('.dropzone a').click((e) => {
  e.stopPropagation();
});
